import { setCountdownNum } from '@/landingPage/common/api';
import { getPageJson } from '@/landingPage/common/utils/fetchCompPropsInPageJson';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { componentsForEach } from 'omega-render';

let countDownNum = -1;
let startTimeStamp = 0;

/** 设置页面全局倒计时时间
 * @param countDown 倒计时时间
 */
export const setABCountDownNum = (countDown: number) => {
    //保证页面的倒计时只设置一次
    if (countDownNum < 0) {
        countDownNum = countDown;
        startTimeStamp = new Date().getTime();
    }
};

/** 离开页面时发送剩余的倒计时时间给后端保存 */
export const sendABCountDownNum = async () => {
    //如果倒计时时间小于0（即没有设置过倒计时，没有命中ab实验倒计时样式）则直接返回
    if (countDownNum < 0) {
        return;
    }
    //设置剩余倒计时时间，最小为0
    const endTimeStamp = new Date().getTime();
    const newCountDownNum = countDownNum - (endTimeStamp - startTimeStamp);
    countDownNum = newCountDownNum < 0 ? 0 : newCountDownNum;

    const params = {
        lpPageId: getUrlParam('pageId'),
        countdown: countDownNum,
    };
    //发送剩余倒计时时间，不需要等待接口返回
    try {
        setCountdownNum(params);
    } catch (e) {
        console.log('e');
    }
};

/**
 * 根据传入的 formSetting和页面的pageJson做判断
 * 如果count开关为true，则不能展示角标提交数 和 轮播样式
 * 如果apply开关为true，则不能展示轮播样式
 * 根据页面json缓存，如果组件中有倒计时组件，则不能展示倒计时样式
 *
 * @param formSetting - 表单设定对象，决定如何设置 extraFormSetting。
 * @returns {Object} 返回设置好的 extraFormSetting 对象。
 */
export const handleExtraFormSetting = (formSetting: any) => {
    const extraFormSetting = {
        enableShowSubmit: true,
        enableShowUser: true,
        enableCountDown: true,
    };

    const pageJSONCache = getPageJson();

    if (formSetting?.count?.switch) {
        extraFormSetting.enableShowSubmit = false;
    }
    if (formSetting?.apply?.switch) {
        extraFormSetting.enableShowUser = false;
    }
    if (pageJSONCache) {
        componentsForEach(pageJSONCache.components, (com) => {
            if (com.type === 'COUNTDOWN_DRAG') {
                extraFormSetting.enableCountDown = false;
            }
        });
    }

    return { ...extraFormSetting };
};
