import { XiansuoFormProps } from '@/landingPage/components/XiansuoFormDrag/XiansuoForm';
import { GameInfo, componentsForEach } from 'omega-render';
import { cloneDeep } from 'lodash';

let pageJSON: any;

export const setPageJsonCache = () => {
    try {
        pageJSON = JSON.parse(window.pageJsonString);
    } catch (e) {}
};

export const getPageJson = () => {
    if (pageJSON) {
        return pageJSON;
    }
    try {
        pageJSON = JSON.parse(window.pageJsonString);
        return pageJSON;
    } catch (e) {
        return;
    }
};

/** 从pageJson中获取小游戏信息 */
export const fetchWechatGameInfoInPageJson = () => {
    const pageJSONCache = getPageJson();
    if (!pageJSONCache) {
        return;
    }
    let wechatGameInfo: GameInfo = {} as GameInfo;

    componentsForEach(pageJSONCache.components, (com) => {
        if (com.type === 'WECHAT_GAME') {
            wechatGameInfo = com.props?.gameInfo;
        }
    });

    return wechatGameInfo;
};

/** 从pageJson中获取表单信息 */
export const needShowInteractionCommentInPageJson = () => {
    const pageJSONCache = getPageJson();
    if (!pageJSONCache) {
        return;
    }
    let needShowComment = false;
    let hasInteractionRecord = false;
    componentsForEach(pageJSONCache.components, (com) => {
        if (['XIANSUO_FORM', 'XIANSUO_FORM_DRAG'].includes(com.type) && !hasInteractionRecord) {
            needShowComment = true;
        }
        if (['DRAG_INTERACTION_RECORD'].includes(com.type)) {
            needShowComment = false;
            hasInteractionRecord = true;
        }
    });
    return needShowComment;
};

/** 获取页面中符合表单弹窗规范的表单组件props
 * 符合弹窗规范的表单类型：表单为普通表单，且表单内组件只有2个
 * 表单弹窗的时候要剔除掉广告主配置的计数展示和用户提交展示
 */
export const fetchXiansuoFormInfoInPageJson = () => {
    const pageJSONCache = getPageJson();
    if (!pageJSONCache) {
        return;
    }
    let flag = false;
    let XiansuoFormInfo: XiansuoFormProps = {} as XiansuoFormProps;
    componentsForEach(pageJSONCache.components, (com) => {
        if (
            !flag &&
            com.type === 'XIANSUO_FORM_DRAG' &&
            com.props?.formOpt.components.length === 2
        ) {
            XiansuoFormInfo = cloneDeep(com.props) as XiansuoFormProps;
            if (XiansuoFormInfo?.formSetting?.count?.switch) {
                XiansuoFormInfo.formSetting.count.switch = false;
            }
            if (XiansuoFormInfo?.formSetting?.apply?.switch) {
                XiansuoFormInfo.formSetting.apply.switch = false;
            }
            flag = true;
        }
    });

    return XiansuoFormInfo;
};
